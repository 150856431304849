import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";

export enum TaskType {
  SCHEDULE_MEMBER_TO_THERAPIST_ASSIGNED_FREQUENCY = "SCHEDULE_MEMBER_TO_THERAPIST_ASSIGNED_FREQUENCY",
  REMIND_MEMBER_ABOUT_FIRST_SESSION_PAPERWORK = "REMIND_MEMBER_ABOUT_FIRST_SESSION_PAPERWORK",
  FOLLOW_UP_AFTER_FIRST_SESSION = "FOLLOW_UP_AFTER_FIRST_SESSION",
  RESPOND_TO_MEMBER_MESSAGE = "RESPOND_TO_MEMBER_MESSAGE",
  UPDATE_PTO_WITHOUT_UTILIZATION_CREDIT = "UPDATE_PTO_WITHOUT_UTILIZATION_CREDIT",
  SEND_PERSONALIZED_WELCOME_MESSAGE = "SEND_PERSONALIZED_WELCOME_MESSAGE",
  FOLLOW_UP_MESSAGE_FOR_CANCELLED_SESSION = "FOLLOW_UP_MESSAGE_FOR_CANCELLED_SESSION",
  FOLLOW_UP_MESSAGE_TWO_DAYS_SINCE_LAST_SESSION_NO_FUTURE_SESSIONS = "FOLLOW_UP_MESSAGE_TWO_DAYS_SINCE_LAST_SESSION_NO_FUTURE_SESSIONS",
  FOLLOW_UP_FIRST_SESSION_COMPLETION_POST_CONQUEROR = "FOLLOW_UP_FIRST_SESSION_COMPLETION_POST_CONQUEROR",
  CUSTOM_TASK = "CUSTOM_TASK",
}

export interface Task {
  id: string;
  resolvable_task_id: string;
  title: string;
  description: string;
  type: TaskType;
  is_derived: boolean;
  is_snoozed: boolean;
  link_url: string | null;
  link_text: string | null;
  remind_at: string | null;
  clinician_email: string;
  is_resolved: boolean | null;
}

export interface TaskGroup {
  title: string;
  description: string | null;
  count: number;
  tasks: Task[];
  type?: string;
}

export interface ClinicianTasksResponse {
  task_groups: TaskGroup[];
}

export const getClinicianTasksQueryKey = (clinicianEmail: string) => [
  "clinician-tasks",
  clinicianEmail,
];

const useClinicianTasks = <T = ClinicianTasksResponse>(
  clinicianEmail: string,
  options?: UseQueryOptions<ClinicianTasksResponse, Error, T>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getClinicianTasksQueryKey(clinicianEmail),
    async ({ signal }) =>
      api
        .get<ClinicianTasksResponse>(`/v1/clinicians/${clinicianEmail}/tasks`, {
          headers: {
            Authorization: accessToken,
          },
          signal,
        })
        .then((res) => res.data)
        .catch(transformAPIError),
    {
      ...options,
    }
  );
};

export default useClinicianTasks;
